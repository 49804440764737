<template>
  
  <div data-app="true" @click="resetTimeBeforeCloseSession()">

    <div id="app_body">

      <router-view></router-view>

      <!-- <div v-if="pageLoaded">
      </div>
      
      <transition name="fade">
        <div v-if="!pageLoaded" id="presentation_container">
          <img src="@/assets/gifts/Intro.gif" alt="">
        </div>
      </transition> -->
      
    </div>
    
  </div>

</template>

<script>
  import { mapActions, mapMutations } from "vuex";
  import ProductCategories from "./store/Modules/ProductCategories";
  import ShoppingCar from "./store/Modules/ShoppingCar";

  export default {
    name: "app",
    data: function() {
      return {
        loading: true // page loaded default false
      };
    },
    async created() {
      await this.setInitialState()
    },
    mounted() {
      let self = this;
      if( self.$route.path == "/" ) {
        setTimeout(function() {
          self.loading = false;
        }, 4500);
      } else {
        self.loading = false;
      }
      if (document.documentElement.clientWidth < 640) {
        // Hay componentes que se deben renderizar solo en la versión web
        this.setIsMobileView(true);
        // console.log("movil");
      } else {
        this.setIsMobileView(false);
        // console.log("Desktop");
      }
    },
    computed: {
      userToken() {
        let token = null;
        if (this.$store.state.SessionStore.user) {
          token = this.$store.state.SessionStore.user.token
        }
        return token;
      },
      dataReady() {
        if (this.$store.state.BuyProcessStore.productCategories && this.$store.state.ShoppingCarStore.shoppingCar) {
          return true
        } else {
          return false
        }
      },
      currentRouteName() {
        return this.$route.name;
      },
      // appStyle() {
      //   let style = {};
      //   if (this.$store.state.bgApp) {
      //     style["backgroundImage"] =
      //       "url(" + require("/" + this.$store.state.bgApp) + ")";
      //   }
      //   return style;
      // },
      pageLoaded() {
        return !this.loading && this.dataReady;
      }
    },
    methods: {
      ...mapMutations(["setIsMobileView", "setIsUserSideBarOpen", "resetTimeBeforeCloseSession"]),
      ...mapActions(['getProductCategories', "logout"]),
      async setInitialState() {
        this.setIsUserSideBarOpen({status: false})
        // *Get disponible categories
        let categoriesResult = await this.getProductCategories();
        this.$store.state.BuyProcessStore.productCategories = new ProductCategories(categoriesResult);
        // *Instance of global shoppingCar
        this.$store.state.ShoppingCarStore.shoppingCar = new ShoppingCar([]);
        this.setSesisionConfig();

        if (window.performance) {
          // console.info("window.performance work's fine on this browser");
        }
        if (performance.getEntriesByType('navigation')[0].type != 'navigate') {
          this.logout().then(() => {
            if (this.$router.currentRoute.path != '/') this.$router.push("/");
          })
        } else {
          // console.info( "This page is not reloaded");
        }
      },
      setSesisionConfig() {
        setInterval(() => {
          if (this.$store.state.SessionStore.timeBeforeCloseSession > 0) {
              this.$store.state.SessionStore.timeBeforeCloseSession -= 1000
          } else {
              if (this.userToken) {
                this.$store.state.ShoppingCarStore.shoppingCar.reset();
                this.logout().then(() => this.$router.push("/"))
              }
          }
        }, 1000);
      }
    },
    components: {
    }
  };
</script>

<style scoped>
  #app_body{
    /* height: 100vh !important;
    background-color: red; */

  }
</style>