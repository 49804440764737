<template>
  <div
    id="content-user-sidebar"
    class="collapsed-animate"
    :class="[ is_opened ? 'w-full h-screen': 'collapsed' ]"
  >

    <!-- Este div vació sirve para poder cerrar la vista dando click fuera del sidebar -->
    <div id="main_hide_container" @click="setIsUserSideBarOpen({status: false, timeout: 0})"></div>
    
    <div id="main_container">
      <t-loader class="loader" v-if="showLoader"/>
      
      <!-- Arrow Back -->
      <div class="arrow_back"
        @click="setIsUserSideBarOpen({status: false, timeout: 0})"
        :class="{
           'bg-white': sidebarStatus.id == 0,
           'bg-white': sidebarStatus.id == 1,
           'bg-white': sidebarStatus.id == 2,
           'bg-white': sidebarStatus.id == 3,
           'bg-white': sidebarStatus.id == 4,
        }"
        >
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-2x"
        />
      </div>

      <div class="header_login">
        <img :src="require('./login_Face.png')">
        <h1>{{ sidebarStatus.detail }} </h1>
      </div>
      
      <!-- 
      <div v-if="sidebarStatus.id == 0" class="login_form">
      </div> -->


      <!-- Login User -->
      <div v-if="sidebarStatus.id == 1" class="login_form">

        <form action="#" v-on:submit.prevent="onSubmitLogin">

          <span>Mi correo es:</span>
          
          <div class="input_container">
            <span>
              <font-awesome-icon
                :icon="['fas', 'user']"
                class="fa-1x text-white"
              />
            </span>
            <input
              v-model="form.email"
              type="email"
              required
              placeholder="correo@correo.com"
              v-on:keydown.enter.prevent="focusTo('password-1')"
            />
          </div>


          <span>Y mi contraseña es:</span>

          <div class="input_container">

            <span>
              <font-awesome-icon
                :icon="['fas', 'key']"
                class="fa-1x text-white"
              />
            </span>
            <input
              v-model="form.password"
              ref="password-1"
              type="password"
              required
              placeholder="********"
              v-on:keydown.enter.prevent="focusTo('submit-1')"
            />
          </div>


          <div class="forgotten_password">
            <a @click="setSidebarStatus(4)">
              Olvidé mi contraseña
            </a>
          </div>

          <button class="btn_submit" type="" :disabled="showLoader===true"
            ref="submit-1">
            Ingresar
          </button>

          
          <transition name="fade-pop">
            <div class="text-red-400">
              {{ sessionError }}
            </div>
          </transition>

          <div>
            <vue-recaptcha ref="recaptcha" size="invisible" @verify="onVerify" @expired="onExpired" :sitekey="siteKey"></vue-recaptcha>
          </div>

        </form>
        
        <p class="extra_redirect" @click="setSidebarStatus(2)">
          <span>------</span>Crear mi cuenta<span>------</span>
        </p>
        
      </div>


      <!-- Create New User -->
      <div v-if="sidebarStatus.id == 2"  class="login_form">
        
        <form action="#" v-on:submit.prevent="onSubmitSignIn">
          
          <span>Mi correo será:</span>
          
          <div class="input_container">
            <span>
              <font-awesome-icon
                :icon="['fas', 'user']"
                class="fa-1x text-white"
              />
            </span>
            <input
              v-model="form.email"
              type="email"
              placeholder="correo@correo.com"
              v-on:keydown.enter.prevent="focusTo('password-2-1')"
            />
          </div>


          <span>Y mi contraseña será:</span>

          <div class="input_container">
            <span>
              <font-awesome-icon
                :icon="['fas', 'key']"
                class="fa-1x text-white"
              />
            </span>
  
            <input
              v-model="form.password"
              ref="password-2-1"
              type="password"
              placeholder="********"
              v-on:keydown.enter.prevent="focusTo('password-2-2')"
            />
          </div>



          <span>Repetir contraseña</span>

          <div class="input_container">
            <span >
              <font-awesome-icon
                :icon="['fas', 'key']"
                class="fa-1x text-white"
              />
            </span>

            <input
              v-model="form.password_c"
              ref="password-2-2"
              type="password"
              placeholder="********"
              v-on:keydown.enter.prevent="focusTo('submit-2')"
            />

          </div>

    
          <button class="btn_submit" type="submit" ref="submit-2">Registrarme</button>

          <transition name="fade-pop">

          <div v-if="sessionError" class="text-red-400">
            {{sessionError}}
          </div>
          </transition>

          <div>
            <vue-recaptcha ref="recaptcha2" size="invisible" @verify="onVerifySignIn" @expired="onExpired" :sitekey="siteKey"></vue-recaptcha>
          </div>     

        </form>

        <p class="extra_redirect" @click="setSidebarStatus(1)">
          <span>------</span>Iniciar Sesión<span>------</span>
        </p>


      </div>

      
      <!-- Sesión logueada -->
      <div v-if="sidebarStatus.id == 3"  class="login_form loged">
          
        <nav>
          <ul>
            <div v-for="(menu, index) in sidebarStatus.routes" :key="index">
              <router-link
                :to="menu.path"
                tag="li"
                :style="{ animationDelay: 0.3 + index * 0.1 + 's' }"
                :class="[!is_opened ? '' : 'apear-2r']"
                @click.native="setIsUserSideBarOpen({status: false, timeout: 0})"
              >
                <a class="element" :content="menu.menu_title">
                  <font-awesome-icon
                    :icon="menu.menu_icon"
                  />
                  <span>{{ menu.menu_title }}</span>
                </a>
              </router-link>
            </div>
          </ul>
        </nav>

        <p class="btn_close_session" @click="$store.dispatch('logout', {redirectTo: '/' })">
          Cerrar sesión
        </p>

      </div>





      <!-- Password Recovery -->
      <div v-if="sidebarStatus.id == 4" class="login_form">
        <div>
          
          <form action="#" v-on:submit.prevent="onSubmitPasswordForgotten">
 
            <span>
              Mi correo es:
            </span>

            <div class="input_container">
              <span>
                <font-awesome-icon
                  :icon="['fas', 'user']"
                  class="fa-1x text-white"
                />
              </span>
              <input
                v-model="form.email"
                type="email"
                placeholder="correo@correo.com"
                v-on:keydown.enter.prevent="focusTo('submit-4')"
              />
            </div>

            <button class="btn_submit" type="submit" ref="submit-4">Resetear password</button>

            <transition name="fade-pop">
              <div v-if="sessionError" class="text-red-400">
                {{ sessionError }}
              </div>
            </transition>
          </form>

        </div>

        <div>

          <p class="extra_redirect" @click="setSidebarStatus(1)">
            <span>------</span>Iniciar Sesión<span>------</span>
          </p>

          <p class="extra_redirect" @click="setSidebarStatus(2)">
            <span>------</span>Crear mi cuenta<span>------</span>
          </p>     
     
        </div>

      </div>

    </div>
        
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { VueRecaptcha } from 'vue-recaptcha';
import { siKey } from "../../constants/config"
//import { copyFileSync } from "fs";
//import { ref, reactive } from '@vue/composition-api'

export default {
  name: "user-sidebar",
  data: function() {
    return {
      currentProfile: "",
      showLoader: false,
      siteKey: siKey,
      frmName: "",
      form: {
        email: "",
        password: "",
        password_c: "",
        site: 3,
        recaptchaToken: "",
        v:0
      },
      sessionError: "",
      sidebarStatus: {
          id: 0,
          detail: "No se ha cargado el tipo de menú"
      },
      sidebarStatusOptions: [
        {
          id: 0,
          detail: "No se ha cargado el tipo de menú"
        },
        {
          id: 1,
          detail: "Inicio de sesión"
        },
        {
          id: 2,
          detail: "Registro Usuario"
        },
        {
          id: 3,
          detail: "Sesión iniciada",
          routes: [
            {
              menu_icon: ['fas', 'file-invoice'],
              menu_title: "Mis pólizas",
              path: "/user/policy-list/"
            },
            {
              menu_icon: ['fas', 'user-edit'],
              menu_title: "Mis datos",
              path: "/user/detail/"
            }
          ]
        },
        {
          id: 4,
          detail: "Recuperar contraseña"
        }
      ]
    };
  },
  props: ["is_opened"],
  components: {
    'vue-recaptcha': VueRecaptcha
  },
  computed: {
    ...mapGetters(["loggedIn"]),
  },
  mounted() {
    if (localStorage.getItem("user")) {
      this.setSidebarStatus(3)
    } else {
      this.setSidebarStatus(1)
    }
  },
  methods: {
    ...mapMutations(["toogleUserSidebar", "setIsUserSideBarOpen"]),
    ...mapActions(["validateHumanUser","getUserDetail"]),
    resetForm() {
      this.form.email = "";
      this.form.password = "";
      this.form.password_c = "";
    },
    onSubmitLogin() {
      this.frmName = "Login";
      this.$refs.recaptcha.execute();
    },

    onVerify: function (recaptchaToken) {

        console.log("onVerify Chat");
        console.log(recaptchaToken);

        let self = this;
        self.sessionError = "";
        self.showLoader = true;
        self.form["recaptchaToken"] = recaptchaToken;
        self.form["v"] = 1;
        self.$refs.recaptcha.reset();

        self.$store.dispatch("getToken", self.form).then(() => {
          self.resetForm();
          self.getUserDetail();
          self.syncData();
          this.frmName = "";
        }).catch((e) => {

          self.$swal({
            icon: "error",
            title: "Correo o Contraseña Incorrecta en la Base de Datos",
            showConfirmButton:false,
            timer: 10000,
          });
          self.showLoader = false;
          throw e;

        }).finally(() => {
          self.showLoader = false;
          this.frmName = "";
        });
    },

    onExpired: function () {
      if(this.frmName == "LogIn"){
        this.$refs.recaptcha.reset();
      }
      else if(this.frmName == "SignIn"){
        this.$refs.recaptcha2.reset();
      }
    },

    async onSubmitSignIn() {
      this.frmName = "SignIn";
      this.$refs.recaptcha2.execute();
    },
    async onVerifySignIn(recaptchaToken){
      let self = this;
      self.showLoader = true;
      self.sessionError = "";
      self.form["recaptchaToken"] = recaptchaToken;
      self.form["v"] = 1;

      let payload = {
        id: null,
        data: self.form
      }

      this.$refs.recaptcha2.reset();

      await self.$store.dispatch("signIn", payload).then(() => {
        self.syncData()
        this.frmName = "";
      }).catch((e) => {
        // console.log(e.response)
        if (e.response.data.non_field_errors) {
          self.sessionError = e.response.data.non_field_errors[0]
        } else if (e.response.data.error) {
          self.sessionError = e.response.data.error
        }
      }).finally(() => {
        self.showLoader = false;
      })
    },
    async onSubmitPasswordForgotten() {
      let self = this;
      self.sessionError = "";
      self.showLoader = true;
      let payload = {
        id: null,
        data: {
          ...self.form,
          "redirect_url": window.location.origin + "/reset_password/:id/"
        }
      }
      
      await self.$store.dispatch("startPasswordReset", payload).then(() => {

        this.$swal({
          icon: "success",
          title: 'Reestablecimiento de contraseña',
          text: '¡Te hemos enviado un correo! Por favor, revisalo para continuar el reestablecimiento de tu contraseña.',
          showConfirmButton: false,
          timer:10000
        })
        setTimeout(() => {
          self.setSidebarStatus(1)
        }, 1500)

      }).catch((e) => {

        this.$swal({
          icon: "error",
          title: 'Ha ocurrido un error!',
          text: e.toString(),
          showConfirmButton: false,
          timer:10000
        })

        console.log(e)

        if (e.response.data.non_field_errors) {
          self.sessionError = e.response.data.non_field_errors[0]
        } else if (e.response.data.error) {
          self.sessionError = e.response.data.error
        }

      }).finally(() => {
        self.showLoader = false;
      })
    },
    setSidebarStatus(status_id) {
      this.sidebarStatus = Object.assign({}, this.sidebarStatus, this.sidebarStatusOptions[status_id])
    },
    close() {
      if ( !this.is_opened ) {
        this.is_opened = true;
      }
    },
    open() {
      this.syncData()
      if (this.is_opened) {
        this.is_opened = false;
      } else {
        this.is_opened = true;
      }
    },
    syncData() {
      if (localStorage.getItem("user")) {
        this.setSidebarStatus(3)
      } else {
        this.setSidebarStatus(1)
      }
    },
    focusTo(ref) {
      this.$refs[ref].click()
      this.$refs[ref].focus()
    }
  },
  watch: {
    loggedIn(newVal) {
      if (newVal == true) {
        this.setSidebarStatus(3);
      } else {
        this.setSidebarStatus(1);
      }
    }
  }
};
</script>