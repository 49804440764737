import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // component: () => import('@/views/Base.vue'),
    component: () => import('@/views/BaseSimple.vue'),
    children: [
      {
        path: "",
        component: () => import('@/views/OurServicesPage.vue'),
        name: "init",
        meta: {
          breadcrumb: "Página de inicio"
        },
      },
      {
        path: "questions",
        component: () => import('@/views/QuestionsPage.vue'),
        name: "questions",
        meta: {
          breadcrumb: "Preguntas frecuentes"
        }
      },
      {
        path: "termsAndConditions",
        component: () => import('@/views/TermsAndConditions.vue'),
        name: "termsAndConditions",
        meta: {
          breadcrumb: "Terminos"
        }
      },
      {
        path: "privacyAdvice",
        component: () => import('@/views/PrivacyAdvice.vue'),
        name: "privacyAdvice",
        meta: {
          breadcrumb: "Terminos"
        }
      },
      {
        path: "services-and-claims",
        component: () => import('@/views/ServicesAndClaimsPage.vue'),
        name: "services-and-claims",
        meta: {
          breadcrumb: "Servicios y Siniestros"
        }
      },
      {
        path: "BillingData",
        component: () => import('@/views/BillingData.vue'),
        name: "BillingData",
        meta: {
          breadcrumb: "BillingData"
        }
      },
      {
        path: "promotions",
        component: () => import('@/views/PromotionsPage.vue'),
        name: "promotions",
        meta: {
          breadcrumb: "Promociones"
        }
      },
      {
        path: "contact-us",
        component: () => import('@/views/ContactUsPage.vue'),
        name: "contact-us",
        meta: {
          breadcrumb: "Contactanos"
        }
      },
      {
        path: "data-for-services",
        component: () => import('@/views/DataForServicesPage.vue'),
        name: "data-for-services",
        meta: {
          breadcrumb: "Cuentame un poco de ti",
          transition: "fade"
        }
      },
      {
        path: "filtered-products",
        component: () => import('@/views/FilteredProductsPage.vue'),
        name: "filtered-products",
        meta: {
          breadcrumb: "Productos adecuados para ti",
          transition: "fade"
        }
      },
      {
        path: "shopping-car",
        component: () => import('@/views/ShoppingCarPage.vue'),
        name: "shopping-car",
        meta: {
          breadcrumb: "Carrito de compra",
          transition: "fade"
        }
      },
      {
        path: "payment-collection",
        component: () => import('@/views/PaymentCollectionPage.vue'),
        name: "payment-collection",
        meta: {
          breadcrumb: "Carrito de compra",
          transition: "fade"
        }
      },
      {
        path: "in-search",
        component: () => import('@/views/InSearchPage.vue'),
        name: "in-search",
        meta: {
          breadcrumb: "Contactanos"
        }
      },
      {
        path: "confirm-email/:id/",
        component: () => import('@/views/ConfirmEmailPage.vue'),
        name: "confirm-email",
        meta: {
          breadcrumb: "Confirmación de registro"
        }
      },
      {
        path: "/user/policy-list/",
        component: () => import('@/views/UserPolicyListPage.vue'),
        name: "user-policy-list",
        meta: {
          breadcrumb: "Listado de pólizas"
        }
      },
      {
        path: "/user/detail/",
        component: () => import('@/views/UserDetailPage.vue'),
        name: "user-detail",
        meta: {
          breadcrumb: "Detalles de usuario"
        }
      },
      {
        path: "/user/register/",
        component: () => import('@/views/UserRegisterPage.vue'),
        name: "user-register",
        meta: {
          breadcrumb: "Registro de usuario"
        }
      },
      {
        path: "/payments/confirm/8/:id/",
        component: () => import('@/views/Payments/PaymentChubb.vue'),
        name: "chubb-payment-confirm"
      },
      {
        path: "/payments/confirm/4/:id/",
        component: () => import('@/views/Payments/PaymentCovid.vue'),
        name: "covid-payment-confirm"
      },
      {
        path: "/payments/confirm/5/:id/",
        component: () => import('@/views/Payments/PaymentDentegra.vue'),
        name: "dentegra-payment-confirm"
      },
      {
        path: "/payments/confirm/11/:id/",
        component: () => import('@/views/Payments/PaymentSurgical.vue'),
        name: "surgical-payment-confirm"
      },
      {
        path: "/payments/confirm/6/:id/",
        component: () => import('@/views/Payments/PaymentFuneral.vue'),
        name: "funeral-payment-confirm"
      },
      {
        path: "/payments/confirm/1/:id/",
        component: () => import('@/views/Payments/PaymentPersonalAccidents.vue'),
        name: "personal-accidents-payment-confirm"
      },
      {
        path: "/reset_password/:id/",
        component: () => import('@/views/UserResetPassword.vue'),
        name: "reset-password"
      },
      {
        path: "/cargos-prueba/",
        component: () => import('@/views/CargosPrueba.vue'),
        name: "cargos-prueba"
      },
      {
        path: '/seguro/:insurance/',
        component: () => import('@/views/MicroSite.vue'),
        name: 'seguro',
      },
    ],
  },
  
  {
    path: "/simple",
    component: () => import('@/views/BaseSimple.vue'),
    children: [
      {
        path: "",
        component: () => import('@/views/OurServicesPageSimple.vue'),
        name: "smp_init",
        meta: {
          breadcrumb: "Página de inicio Secundaria"
        },
      },
      {
        path: "data-for-services",
        component: () => import('@/views/DataForServicesPage.vue'),
        name: "data-for-simple-services",
        meta: {
          breadcrumb: "Cuentame un poco de ti",
          transition: "fade"
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router;